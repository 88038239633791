<template>
  <MobileDefault v-if="isMobile">
    <div class="container-bg-white container-space-mobile-default">
      <h1 class="text-sm">Thanh toán Nâng cấp tài khoản</h1>
      <a-spin style="height: 80vh; width: 100%; display: grid; place-items: center" v-if="!voucherItem"></a-spin>
      <template v-else>
        <div class="mb-5">
          <h2 class="text-sm font-normal"><span class="border-left-line-mobile"></span>Thông tin chi tiết thanh toán</h2>
          <div class="flex justify-center">
            <div class="flex flex-col justify-space-between items-center justify-center text-center">
              <div v-if="voucherItem.name" class="plan-pricing">
                {{ voucherItem.name }}
              </div>
              <div class="my-2">
                <div v-if="voucherItem.content || voucherItem.discountPrice" class="text-xl font-semibold">{{ voucherItem.discountPrice || voucherItem.price | formatCurrency}}</div>
<!--                <div v-if="voucherItem.discountPrice" class="plan-sub-content" style="text-decoration: line-through; color: gray;">{{ voucherItem.price | formatCurrency}}</div>-->
              </div>
              <a-select default-value="lucy" style="width: 190px;" disabled>
                <a-select-option value="lucy">
                  {{voucherItem.content}}
                </a-select-option>
              </a-select>
            </div>
          </div>
        </div>
        <h2 v-if="voucherItem" class="text-sm font-normal my-2"><span class="border-left-line-mobile"></span>Thanh toán</h2>
        <div class="bank-section text-xs">
          <div v-for="bankAccount in LIST_BANK_ACCOUNT" :key="bankAccount.id" class="bank-account-container">
            <div class="status-box">
              <span style="text-transform: uppercase; margin-left: 14px;">Thông tin thanh toán</span>
            </div>
            <div class="bank-info-mobile">
              <strong class="margin-right-25">{{ bankAccount.bankName }}</strong>
              <img v-if="bankAccount.urlLogo" :src="bankAccount.urlLogo" height="16px" width=""
                   :alt="`${bankAccount.bankName}_logo`">
            </div>
            <div class="bank-info-mobile">
              <span>Số tài khoản: </span>
              <div>
                <strong>{{ bankAccount.accountNumber }}</strong>
                <div class="tooltip">
                  <div type="default" size="small"
                       @click="onClickCopy(bankAccount.accountNumber, 'account_number_tooltip_mobile')">
                    <span class="tooltiptext" id="account_number_tooltip_mobile">Đã copy</span>
                    <a-icon type="copy" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bank-info-mobile">
              <span>Chủ tài khoản: </span>
              <div class="flex flex-row justify-end">
                <div class="text-right"><strong style="text-transform: uppercase;">{{ bankAccount.accountName }}</strong></div>
                <div class="tooltip">
                  <div type="default" size="small"
                       @click="onClickCopy(bankAccount.accountName, 'account_owner_tooltip_mobile')">
                    <span class="tooltiptext" id="account_owner_tooltip_mobile">Đã copy</span>
                    <a-icon type="copy" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bank-info-mobile">
              <span>Số tiền: </span>
              <div v-if="voucherItem.discountPrice">
                <strong>{{ voucherItem.discountPrice | formatCurrency }}</strong>
                <div class="tooltip">
                  <div type="default" size="small"
                       @click="onClickCopy(voucherItem.discountPrice, 'discount_price_tooltip_mobile')">
                    <span class="tooltiptext" id="discount_price_tooltip_mobile">Đã copy</span>
                    <a-icon type="copy" />
                  </div>
                </div>
              </div>
              <div v-else>
                <strong>{{ voucherItem.price | formatCurrency }}</strong>
                <div class="tooltip">
                  <div type="default" size="small" @click="onClickCopy(voucherItem.price, 'price_tooltip_mobile')">
                    <span class="tooltiptext" id="price_tooltip_mobile">Đã copy</span>
                    <a-icon type="copy" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bank-info-mobile">
              <span>Nội dung chuyển khoản <strong class="text-primary-500">*</strong>: </span>
              <div>
                <span class="payment-status status-process">{{ voucherCode }}</span>
                <div class="tooltip">
                  <div type="default" size="small" @click="onClickCopy(voucherCode, 'transfer_content_tooltip_mobile')">
                    <span class="tooltiptext" id="transfer_content_tooltip_mobile">Đã copy</span>
                    <a-icon type="copy" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bank-info-mobile" v-if="transactionData && transactionData.init_time">
              <span>Ngày tạo: </span>
              <strong>{{transactionData.init_time | formatDate}}</strong>
            </div>
            <div class="bank-info-mobile" style="border-bottom: none" v-if="transactionData && transactionData.init_time">
              <span>Trạng thái: </span>
              <div>
                <p class="status-box mobile-view">
                  <span v-if="isSuccessfulPayment === null" class="payment-status status-process">Đang xác minh thanh toán <span v-if="timeCountDown"> - <strong>{{timeCountDown | formatTime}}</strong></span></span>
                  <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success"><a-icon type="check-circle" style="margin-right: 4px;"/>Thanh toán thành công !</span>
                  <span v-else-if="isSuccessfulPayment === false">
                    <span class="payment-status status-failure"><a-icon type="info-circle" style="margin-right: 4px"/>Xác minh thanh toán Thất bại</span>
                  </span>
                </p>
              </div>
            </div>
            <div class="mobile-view text-center text-xs my-4">
              <template v-if="isSuccessfulPayment === null">
                <a-button type="primary" size="large" @click="onClickReCheck" style="width: 180px">
                  Kiểm tra lại
                  <a-spin class="spin-recheck" v-if="isChecking"></a-spin>
                </a-button>
              </template>
              <template v-if="isSuccessfulPayment === false">
                <a-button type="primary" size="large" @click="onClickReBuy" style="width: 180px" class="mb-2">
                  Mua lại
                  <a-spin class="spin-recheck" v-if="isChecking"></a-spin>
                </a-button>
                <div class="text-note">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">mua lại</span> hoặc liên hệ <span class="text-action" @click="onClickSupport">hỗ trợ</span></div>
              </template>
              <template v-if="isSuccessfulPayment === true">
                <a-button type="primary" size="large" @click="onClickSearch" style="width: 180px">
                  Bắt đầu sử dụng
                </a-button>
              </template>
            </div>

            <div class="note-section" style="margin-left: 14px; margin-top: 32px">
              <p style="font-size: 12px"><span class="text-primary-500">*</span>Bắt buộc</p>
              <p style="font-size: 10px;">Nội dung chuyển khoản bắt buộc là <span class="payment-status status-process">{{ voucherCode }}</span> để việc thanh toán được xác minh tự động (2-5 phút)
              </p>
            </div>
          </div>
        </div>
      </template>
    </div>
  </MobileDefault>
  <Default v-else class="page-search">
    <div class="payment-page container-space-default">
      <h1 class="title"><span class="border-left-line"></span>Thông tin chi tiết thanh toán</h1>
      <a-spin style="height: 80vh; width: 100%; display: grid; place-items: center" v-if="!voucherItem"></a-spin>
      <div class="page-content" v-else>
        <div class="payment-section">
          <div class="voucher-info-section">
            <div class="plan">
              <div v-if="voucherItem.name" class="plan-name">{{voucherItem.name}}</div>
              <div class="plan-content-container">
                <div v-if="voucherItem.content" class="plan-content">{{voucherItem.content}}</div>
                <div v-if="voucherItem.sub_content" class="plan-sub-content">({{voucherItem.sub_content}})</div>
              </div>

              <div v-if="voucherItem.discountPrice" class="plan-pricing">{{voucherItem.discountPrice | formatCurrency}}</div>
              <div v-if="voucherItem.price" class="plan-pricing" :class="{'old-price': voucherItem.discountPrice}">{{voucherItem.price | formatCurrency}}</div>
            </div>
            <p class="text-note">{{voucherItem.promoted_text}}</p>
          </div>
          <div class="bank-section">
            <div v-for="bankAccount in LIST_BANK_ACCOUNT" :key="bankAccount.id" class="bank-account-container">
              <div class="status-box">
                <strong style="text-transform: uppercase; margin-right: 8px">Thông tin thanh toán</strong>
              </div>
              <div class="bank-info">
                <strong>{{bankAccount.bankName}}</strong>
                <img v-if="bankAccount.urlLogo" :src="bankAccount.urlLogo" height="20px" width="" :alt="`${bankAccount.bankName}_logo`">
              </div>
              <div v-if="bankAccount.accountAddress">{{bankAccount.accountAddress}}</div>
              <div class="bank-info">
                <span>Số tài khoản: </span>
                <div>
                  <strong>{{bankAccount.accountNumber}}</strong>
                  <div class="tooltip">
                    <a-button type="default" size="small" @click="onClickCopy(bankAccount.accountNumber, 'account_number_tooltip')">
                      <span class="tooltiptext" id="account_number_tooltip">Đã copy</span>
                      <span>copy</span>
                    </a-button>
                  </div>
                </div>
              </div>
              <div class="bank-info">
                <span>Chủ tài khoản: </span>
                <div>
                  <strong style="text-transform: uppercase">{{bankAccount.accountName}}</strong>
                  <div class="tooltip">
                    <a-button type="default" size="small" @click="onClickCopy(bankAccount.accountName, 'account_owner_tooltip')">
                      <span class="tooltiptext" id="account_owner_tooltip">Đã copy</span>
                      <span>copy</span>
                    </a-button>
                  </div>
                </div>
              </div>
              <div class="bank-info">
                <span>Số tiền: </span>
                <div v-if="voucherItem.discountPrice">
                  <strong>{{voucherItem.discountPrice | formatCurrency}}</strong>
                  <div class="tooltip">
                    <a-button type="default" size="small" @click="onClickCopy(voucherItem.discountPrice, 'discount_price_tooltip')">
                      <span class="tooltiptext" id="discount_price_tooltip">Đã copy</span>
                      <span>copy</span>
                    </a-button>
                  </div>
                </div>
                <div v-else>
                  <strong>{{voucherItem.price | formatCurrency}}</strong>
                  <div class="tooltip">
                    <a-button type="default" size="small" @click="onClickCopy(voucherItem.price, 'price_tooltip')">
                      <span class="tooltiptext" id="price_tooltip">Đã copy</span>
                      <span>copy</span>
                    </a-button>
                  </div>
                </div>
              </div>
              <div class="bank-info">
                <span>Nội dung chuyển khoản <strong class="text-required">(bắt buộc)</strong>: </span>
                <div>
                  <span class="payment-status status-process">{{voucherCode}}</span>
                  <div class="tooltip">
                    <a-button type="default" size="small" @click="onClickCopy(voucherCode, 'transfer_content_tooltip')">
                      <span class="tooltiptext" id="transfer_content_tooltip">Đã copy</span>
                      <span>copy</span>
                    </a-button>
                  </div>
                </div>
              </div>
              <div class="bank-info" v-if="transactionData && transactionData.init_time">
                <span>Ngày tạo: </span>
                <div>
                  <strong>{{transactionData.init_time | formatDate}}</strong>
                  <div class="tooltip" style="visibility: hidden">
                    <a-button type="default" size="small">
                      <span>copy</span>
                    </a-button>
                  </div>
                </div>
              </div>
              <div class="cta-section">
                <div>
                  <p class="status-box">
                    <span v-if="isSuccessfulPayment === null" class="payment-status status-process">Đang xác minh thanh toán <span v-if="timeCountDown"> - <strong>{{timeCountDown | formatTime}}</strong></span></span>
                    <span v-else-if="isSuccessfulPayment === true" class="payment-status status-success"><a-icon type="check-circle" style="margin-right: 4px;"/>Thanh toán thành công !</span>
                    <span v-else-if="isSuccessfulPayment === false">
                        <span class="payment-status status-failure">Xác minh thanh toán Thất bại</span><span class="text-note">Hãy <span class="text-action" style="color: #ff7227" @click="onClickReBuy">làm lại</span> hoặc liên hệ <span class="text-action" @click="onClickSupport">hỗ trợ</span></span>
                      </span>
                  </p>
                </div>
                <template v-if="isSuccessfulPayment === null">
                  <a-button type="primary" size="large" @click="onClickReCheck">
                    Kiểm tra lại
                    <a-spin class="spin-recheck" v-if="isChecking"></a-spin>
                  </a-button>
                </template>
                <template v-if="isSuccessfulPayment === true">
                  <a-button type="primary" size="large" @click="onClickSearch">
                    Bắt đầu sử dụng
                  </a-button>
                </template>
<!--                <template v-else-if="isSuccessfulPayment === false">-->
<!--                  <a-button type="primary" size="large" @click="onClickReBuy">-->
<!--                    Mua lại-->
<!--                  </a-button>-->
<!--                </template>-->
              </div>
              <div class="note-section">
                <strong class="text-required">Quan trọng:</strong>
                <ul class="section-content">
                  <li>Nội dung chuyển khoản bắt buộc là <span class="payment-status status-process">{{voucherCode}}</span> để việc thanh toán được xác minh tự động (2-5 phút)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Default>
</template>

<script>
import {MESSENGER_ID} from "@/constant/general/GeneralConstant";
import {LIST_VOUCHER, LIST_BANK_ACCOUNT, TRANSACTION_STATE} from "@/constant/pricing/PricingPlansConstant";
import {formatCurrency} from "@/helper/FormatHelper";
import {MutationUser} from "@/store/modules/UserModule/mutation";
import {getTransactionState, getTransactionData, findPlanInfo} from "@/service/payment/PaymentService";
import {getPaidUserInformation, getUserPermissionFromRoles} from "@/service/user/UserPermissionService";
import Default from "@/layout/Default";
import {
  fbTrackingPurchase,
  generateContentTrackingFb
} from "@/service/tracking/FacebookTrackingService";
import {copyToClipboard} from "@/helper/StringHelper";
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {removeVoucherWaiting, updateProfileUserCurrent} from "@/service/user/UserProfileService";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";
import MobileDefault from "@/layout/MobileDefault";

export default {
  name: "TransactionDetailView",
  components: {
    Default,
    MobileDefault
  },
  data() {
    return {
      currentStep: 0,
      processStep: 0,
      voucherItem: null,
      LIST_BANK_ACCOUNT: LIST_BANK_ACCOUNT,
      LIST_VOUCHER: LIST_VOUCHER,
      MESSENGER_ID,
      isSuccessfulPayment: null,
      isChecking: false,
      timeOutPayment: 0,
      timeCountDown: 0,
      transactionData: null
    }
  },
  async mounted() {
    const selectedVoucherId = this.$route.params['voucherId']
    this.voucherCode = this.$route.params['voucherCode']
    this.voucherItem = findPlanInfo(selectedVoucherId)
    this.transactionData = await getTransactionData(this.voucherCode)
    this.voucherItem.discountPrice = this.voucherItem.price != this.transactionData.voucher_price ? this.transactionData.voucher_price : null
    await this.finishVerifyPayment(this.transactionData.status)
    let timeOut = new Date(this.transactionData.expired_time) - new Date()
    if (timeOut <= 0) {
      return
    }
    this.timeOutPayment = Math.floor(timeOut/1000)
    this.timeCountDown = this.timeOutPayment
    let timeLoop = setInterval(() => {
      this.timeCountDown = this.timeCountDown !== 0 ? this.timeCountDown - 1 : 0
    }, 1000)
    setTimeout(async () => {
      clearInterval(timeLoop)
      const transactionData = await getTransactionData(this.voucherCode)
      await this.finishVerifyPayment(transactionData.status)
    }, 1000 * this.timeOutPayment)
  },
  methods: {
    async updatePaidUserInfo() {
      let voucherInformation = await getPaidUserInformation()
      if (!voucherInformation) {
        return
      }
      const expirationDate = new Date(voucherInformation.end_query_time)
      const isExpired = new Date() - expirationDate > 0
      let freeSearchCount = voucherInformation.remain_free_search_count
      freeSearchCount = freeSearchCount && typeof freeSearchCount === 'number'
        ? freeSearchCount
        : 0
      this.$store.dispatch(`UserModule/${MutationUser.setIsExpired}`, isExpired)
      this.$store.dispatch(`UserModule/${MutationUser.setExpirationDate}`, expirationDate)
      this.$store.dispatch(`UserModule/${MutationUser.freeSearchCount}`, freeSearchCount)
      const response = await updateProfileUserCurrent()
      let permissionName = getUserPermissionFromRoles(response?.roles)?.name
      this.$store.commit(`UserModule/${MutationUser.permissionAvailableName}`, permissionName)
      this.$store.commit(`UserModule/${MutationUser.permissionPlanName}`, permissionName)
    },
    async finishVerifyPayment(transactionState) {
      switch (transactionState) {
        case TRANSACTION_STATE.COMPLETE: {
          this.isSuccessfulPayment = true
          await this.updatePaidUserInfo()
          removeVoucherWaiting([this.voucherCode])
          const product = generateContentTrackingFb(this.selectedVoucherId, 1, this.voucherItem.price)
          fbTrackingPurchase([product])
          break
        }
        case TRANSACTION_STATE.ERROR: {
          removeVoucherWaiting([this.voucherCode])
          this.isSuccessfulPayment = false
          break
        }
        case TRANSACTION_STATE.WAITING: {
          this.isSuccessfulPayment = null
          break
        }
      }
    },
    onClickCopy(text, tooltipId) {
      let result = copyToClipboard(text)
      if (result) {
        document.getElementById(tooltipId).classList.add('show')
        setTimeout(() => {
          document.getElementById(tooltipId).classList.remove('show')
        }, 700)
      }
    },
    async onClickReCheck() {
      this.isChecking = true
      const statusPayment = await getTransactionState(this.voucherCode)
      await this.finishVerifyPayment(statusPayment)
      this.isChecking = false
    },
    onClickSearch() {
      this.$router.push({name: 'Home'})
    },
    onClickReBuy() {
      // if (window) {
      //   window.location.reload()
      // } else {
        this.onChangeVoucher()
      // }
    },
    onClickSupport() {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSupport}`, true)
    },
    onChangeVoucher() {
      this.$router.push({name: 'pricing'})
    },
  },
  mixins: [
    mixinStoreGetterGlobal
  ],
  filters: {
    formatCurrency,
    formatTime(value) {
      if (typeof value !== "number") {
        return 'hết hạn'
      }
      let sec = value%60
      let min = (value - sec)/60
      if (min === 0 && sec ===0) {
        return 'hết hạn'
      } else {
        return `${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`
      }
    },
    formatDate(value) {
      let date = new Date(value)
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-page {
  width: 100%;
  background-color: white;
  position: relative;
  font-size: 16px;
  margin: 16px;

  .page-content {
    width: 90%;
    min-width: 500px;
    max-width: 1000px;
    margin: auto;
    padding: 16px;

    .header-serction {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .title {
        font-size: 24px;
        line-height: 28px;
        color: $--color-primary;
        font-weight: bold;
      }
    }

    .payment-section {
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: flex-start;
      margin: 16px 0;

      .voucher-info-section {
        margin-right: 60px;
        text-align: center;

        .plan {
          height: 230px;
          width: 200px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border: 1px solid $--color-primary;
          border-radius: 8px;
          padding: 12px;
          margin: 12px 0 0 0;

          .plan-name {
            font-size: 16px;
            font-weight: $--font-weight-primary;
          }

          .plan-content-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            flex-grow: 1;
          }

          .plan-content {
            font-size: 30px;
            line-height: 34px;
            padding-bottom: 4px;
            color: $--color-primary;

          }

          .plan-sub-content {
            font-size: 16px;
            line-height: 18px;
          }

          .plan-pricing {
            font-weight: $--font-weight-primary;
            font-size: 20px;
            margin: 4px;

            &.old-price {
              font-size: 16px;
              font-weight: normal;
              text-decoration: line-through;
              color: gray;
            }
          }

          .btn-buy {
            width: 80%;

            span {
              text-transform: uppercase;
            }
          }
        }
      }

      .bank-section {
        flex-grow: 1;
        max-width: 620px;
        line-height: 2.2rem;

        .bank-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
        }
      }
    }

    .cta-section {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }

    .note-section {
      text-align: left;
      margin-top: 80px;
    }
  }
}

.time-countdown {
  padding: 6px;
  border-radius: 4px;
  background-color: rgba($--color-primary, 0.2);
  color: $--color-primary
}

.payment-status {
  padding: 6px;
  border-radius: 4px;
  background-color: rgba(gray, 0.2);
  color: gray;

  &.status-process {
    background-color: rgba($--color-primary, 0.2);
    color: $--color-primary
  }

  &.status-success {
    padding: 8px;
    background-color: rgba(#127C49, 0.2);
    color: #127C49;
  }

  &.status-failure {
    margin-right: 4px;
    background-color: rgba(#ee0033, 0.2);
    color: #ee0033;
  }
}

.mobile-view {

  .payment-status {
    background-color: transparent;

    &.status-process {
      padding: 0;
      background-color: transparent;
      color: $--color-primary
    }

    &.status-success {
      background-color: transparent;
      color: #127C49;
    }

    &.status-failure {
      margin-right: 0;
      background-color: transparent;
      color: #ee0033;
    }
  }
}

.bank-section {

  .bank-info-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    border-bottom: 1px solid #E3E3E3;
  }

  .cta-mobile-section {
    font-size: 12px;
    display: flex;
    justify-content: center;
    text-align: center;

    .cta-btn {
      padding: 8px;
      color: white;
      border-radius: 0.25rem;
      cursor: pointer;
      background-color: #ff7227;
      margin-top: 16px;
      margin-bottom: 16px;
      min-width: 180px;
    }
  }
}

.border-left-line {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 1);
}

.dash-border-bottom {
  width: 100%;
  border-bottom: 1px gray dashed;
  margin: 8px 0;
}

.text-note {
  margin-top: 4px;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
}

.text-action {
  text-decoration: underline;
  text-align: left;
  cursor: pointer;

  &:hover {
    color: $--color-primary;
  }
}

.text-highlight {
  padding: 6px;
  border-radius: 4px;
  background-color: rgba($--color-primary, 0.2);
  color: $--color-primary;
}

.text-required {
  color: #ee0033;
  text-transform: uppercase;
}

.tooltip {
  position: relative;
  display: inline-block;
  margin-left: 8px;

  button {
    border: none;
    background: rgba(180, 185, 230, 0.27);
    color: #2180F0;
  }
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 100%;
  color: #2180F0;
  position: absolute;
  z-index: 1;
  text-align: left;
  opacity: 0;
  transition: opacity 0.5s;
  left: 110%;
}

.tooltip .tooltiptext#popup_upgrade_tooltip {
  left: 100%;
  bottom: 0;
  padding-left: 10px;
}

.tooltip .tooltiptext.show {
  visibility: visible;
  opacity: 1;
}
.spin-recheck {
  margin-left: 12px;

  /deep/ {
    .ant-spin-dot-item {
      background-color: white;
    }
  }
}
</style>
